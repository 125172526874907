import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";

export default ({ data }) => {
  let markdown = data.allMarkdownRemark.edges;
  let scripts = markdown.filter(({node}) => node.fields.section === 'scripts');
  let sketches = markdown.filter(({node}) => node.fields.section === 'writing');
  let allMediumPosts = data.allMediumPost.edges;
  let mediumBlackList = [
    "jeff-sessions-week-jeff-sessions-children-ranked-in-order-of-how-successfully-theyve-hunted-me-b1d098f02483",
    "the-president-sat-in-some-kind-of-big-car-c1cfde7f14",
    "exclusive-video-the-shocker-interviews-steve-bannon-9c05e51ede7f",
    "is-mike-pence-an-extremely-tall-old-baby-b614cc50ff7a"
  ];
  let mediumPosts = allMediumPosts.filter(({node}) => !mediumBlackList.includes(node.uniqueSlug));

  return (
    <Layout name="writing">
        <section>
          <h2>Sketches</h2>
          <ul className='writing-pieces-list'>
            {sketches.map(({node}, index) => {
              let char = node.frontmatter.title.length;
              let size = (char < 20) ? '2.5rem' : '1.5rem';
              return(
                <Link to={node.fields.slug}>
                  <li style={{fontSize: size}} >{node.frontmatter.title} </li>
                </Link>
              );
            })}
          </ul>
        </section>
        <section>
          <h2>Scripts</h2>
          <ul className="writing-pieces-list">
            {scripts.map(({node}, index) => {
                let char = node.frontmatter.title.length;
                let size = (char < 20) ? '2.5rem' : '1.5rem';
                return(
                    <Link to={node.fields.slug}>
                      <li style={{fontSize: size}} >{node.frontmatter.title} </li>
                    </Link>
                );
            })}
          </ul>
        </section>
        <section>
          <h2>Medium Articles</h2>
          <ul className='writing-pieces-list medium'>
            {mediumPosts.map(({node}) => {
              let char = node.title.length;
              let size = (char < 20) ? '2.5rem' : '1.5rem';
              return (
                <a href={`https://medium.com/the-shocker/${node.uniqueSlug}`} target='_blank' rel='noreferrer noopen' className='medium-link'>
                <li style={{fontSize: size}}>{node.title}</li>
                </a>
              )
            })}
          </ul>
        </section>
    </Layout>
  );
}

export const query = graphql`
query MyQuery {
  allMarkdownRemark {
    edges {
      node {
      fields {
        slug
        section
      }
      frontmatter {
        title
      }
      }
    }
  }
  allMediumPost {
    edges {
      node {
        title
        displayAuthor
        content {
          metaDescription
          postDisplay {
            coverless
          }
          subtitle
        }
        importedUrl
        webCanonicalUrl
        uniqueSlug
      }
    }
  }
}
`


